import { useDiscountStore } from "~/stores/useDiscountStore";

export async function usePartnerContent() {
  const { $pinia } = useNuxtApp();
  const discountStore = useDiscountStore($pinia);
  const { code, partnerContent, partnerContentStatus } = storeToRefs(discountStore);

  if (!partnerContent.value && partnerContentStatus.value !== "pending" && code.value !== "") {
    await discountStore.executeGetPartnerContent();
  }

  return { data: partnerContent, status: partnerContentStatus };
}
